/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql, Link } from "gatsby";
import { ThemeProvider } from "theme-ui";
import theme from "../gatsby-plugin-theme-ui/index.js";
import Layout from "../components/Layout";
//import SEO from "../components/seo";

const Home = ({ data }) => {
  // const siteTitle = data.site.siteMetadata.title;
  // const siteDescription = data.site.siteMetadata.description;
  const posts = data.allMdx.nodes;

  return (
    <ThemeProvider theme={theme}>
      {/* <SEO title={siteTitle} description={siteDescription} /> */}
      <Layout>
        <div
          sx={{
            flex: 1,
            flexDirection: "column",
            display: "flex",
            paddingTop: "6em"
          }}
        >
          <div
            sx={{
              flex: 1,
              minWidth: 0,
              width: ["90%", "80%", "60%"],
              maxWidth: "1300px",
              margin: "auto",
            }}
          >
            <h1>Blog Posts</h1>
            <div
              sx={{
                display: "grid",
                gridGap: "10px",
                gridTemplateColumns: ["100%", "50% 50%"],
              }}
            >
              {posts.map((post) => {
                const title = post.frontmatter.title || post.slug;
                return (
                  <div
                    sx={{
                      backgroundColor: "secondary",
                      borderRadius: "10px",
                      padding: "10px",
                      m: "20px",
                      "&:hover": {
                        boxShadow: (theme) => `0 0 4px ${theme.colors.primary}`,
                      },
                    }}
                    key={post.slug}
                  >
                    <Link
                      sx={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      to={post.slug}
                    >
                      <p
                        sx={{
                          marginTop: "-.1em",
                          fontWeight: "normal",
                        }}
                      >
                        {post.frontmatter.date}
                      </p>
                      <h1
                        sx={{
                          marginTop: "-.5em",
                        }}
                      >
                        {" "}
                        {title}
                      </h1>

                      <p
                        sx={{
                          marginTop: "-1em",
                          p: ".5em",
                        }}
                      >
                        {post.frontmatter.description}
                      </p>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Layout>
    </ThemeProvider>
  );
};

export default Home;

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        description
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        slug
        frontmatter {
          date(formatString: "Do MMMM YYYY")
          description
          title
          embeddedImagesLocal {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        }
      }
    }
  }
`;
